<template>
  <div>
    <page-header-admin
      class="mb-2"
      :title="$t('Purchased subscriptions')"
    />
    <b-card no-body>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                type="search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table-simple responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>
              {{ $t('Package') | upper }}
            </b-th>
            <b-th>
              {{ $t('Mentee') | upper }}
            </b-th>
            <b-th>
              {{ $t('Purchase date') | upper }}
            </b-th>
            <b-th>
              {{ $t('Scholarship') | upper }}
            </b-th>
            <b-th>
              {{ $t('Price') | upper }}
            </b-th>
            <b-th />
            <b-th>
              {{ $t('Status') | upper }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(subscription) in visiblePurchasedSubscriptions">
            <b-tr :key="subscription.subscription_id">
              <b-th :rowspan="subscription.packages.length">
                {{ $t(subscription.subscription_title) }}
              </b-th>
              <b-td :rowspan="subscription.packages.length">
                <b-link :to="{ name: 'admin-mentee-page', params: { id: subscription.mentee.id } }">
                  {{ subscription.mentee.user.name }} {{ subscription.mentee.user.surname }}
                </b-link>
              </b-td>
              <b-td :rowspan="subscription.packages.length">
                {{ subscription.purchased_at }}
              </b-td>
              <b-td :rowspan="subscription.packages.length">
                <b-card-text v-if="subscription.scholarship">
                  {{ $t('Yes') }} ({{ subscription.scholarship.name }})
                </b-card-text>
                <b-card-text v-else>
                  {{ $t('No') }}
                </b-card-text>
              </b-td>
              <b-td :rowspan="subscription.packages.length">
                <div class="d-flex flex-row align-items-center">
                  {{ subscription.price | price }}
                  <b-button
                    v-if="subscription.packages[0].status == packageStatuses.PACKAGE_STATUS_AWAITING_PAYMENT"
                    variant="primary"
                    class="ml-2 mark-as-paid-button"
                    @click="markAsPaid(subscription)"
                  >
                    {{ $t('Mark as paid') }}
                  </b-button>
                </div>
              </b-td>
              <b-td>
                {{ $t(subscription.packages[0].description) }}
              </b-td>
              <b-td>
                <b-badge :variant="packageStatusToColorVariant(subscription.packages[0].status)">
                  {{ packageStatusToText(subscription.packages[0].status) }}
                </b-badge>
              </b-td>
            </b-tr>
            <b-tr
              v-for="position in subscription.packages.length - 1"
              :key="subscription.subscription_id + '-' + position"
            >
              <b-td>
                {{ $t(subscription.packages[position].description) }}
              </b-td>
              <b-td>
                <b-badge :variant="packageStatusToColorVariant(subscription.packages[position].status)">
                  {{ packageStatusToText(subscription.packages[position].status) }}
                </b-badge>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            v-model="currentPage"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <mark-as-paid-confirmation-modal
      ref="mark-as-paid-confirmation-modal"
      @mark-as-paid-confirmed="markAsPaidConfirmed"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTableSimple,
  BTbody,
  BThead,
  BTd,
  BTh,
  BTr
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { useUserPackagesRepository, useSubscriptionRepository, useSubscription } from '@mentoring-platform/composables'
import { upper, price } from '@mentoring-platform/utils/filters'
import { packageStatuses } from '@mentoring-platform/constants'
import MarkAsPaidConfirmationModal from '@mentoring-platform/views/components/blocks/PurchasedSubscriptions/MarkAsPaidConfirmationModal.vue'

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTableSimple,
    BTbody,
    BThead,
    BTd,
    BTh,
    BTr,
    MarkAsPaidConfirmationModal,
    PageHeaderAdmin,
    vSelect
  },
  filters: {
    price,
    upper
  },
  data() {
    return {
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      sortBy: 'id',
      isSortDirDesc: false,
      packageStatuses,
      searchQuery: null
    }
  },
  computed: {
    totalRows() {
      if (this.searchResults) {
        return this.searchResults.length
      }
      return 0
    },
    searchResults() {
      if (this.searchQuery) {
        const regex = new RegExp(this.searchQuery.trim(), 'gi')
        return this.purchasedSubscriptions.filter(
          subscription => this.$t(subscription.subscription_title).match(regex)
          || subscription.scholarship?.name.match(regex)
          || `${subscription.mentee.user.name} ${subscription.mentee.user.surname}`.match(regex)
          || subscription.mentee.user.email.match(regex)
          || subscription.packages.filter(subscriptionPackage => this.$t(subscriptionPackage.description).match(regex)).length > 0
        )
      }
      return this.purchasedSubscriptions
    },
    visiblePurchasedSubscriptions() {
      if (this.searchResults) {
        const start = (this.currentPage - 1) * this.perPage
        return this.searchResults.slice(start, start + this.perPage)
      }
      return []
    }
  },
  methods: {
    markAsPaid(subscription) {
      this.$refs['mark-as-paid-confirmation-modal'].show(subscription)
    },
    markAsPaidConfirmed(subscription) {
      this.markSubscriptionAsPaid(subscription)
        .then(() => {
          this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
            title: this.$t('Confirmation'),
            variant: 'success',
            solid: true,
          })
          this.getPurchasedSubscriptions()
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
            title: this.$t('Caution'),
            variant: 'danger',
            solid: true,
          })
        })
    }
  },
  setup() {
    const { packageStatusToText, packageStatusToColorVariant } = useUserPackagesRepository()
    const { purchasedSubscriptions, getPurchasedSubscriptions } = useSubscriptionRepository()
    const { markSubscriptionAsPaid } = useSubscription()
    getPurchasedSubscriptions()

    return {
      packageStatusToText,
      packageStatusToColorVariant,
      purchasedSubscriptions,
      getPurchasedSubscriptions,
      markSubscriptionAsPaid
    }
  },
}
</script>

<style lang="scss" scoped>
 .mark-as-paid-button {
   min-width: 150px;
 }
</style>
