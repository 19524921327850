<template>
  <b-modal
    id="mark-as-paid-confirmation-modal"
    ref="mark-as-paid-confirmation-modal"
    centered
    size="lg"
    :title="$t('Mark as paid confirmation')"
    title-class="font-weight-bolder"
    :ok-title="$t('Payment confirmation')"
    @ok="confirmed"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
  >
    <b-card-text>
      {{ $t('Mark as paid confirmation message') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {
      subscription: null
    }
  },
  methods: {
    show(subscription) {
      this.subscription = subscription
      this.$refs['mark-as-paid-confirmation-modal'].show()
    },
    confirmed() {
      this.$emit('mark-as-paid-confirmed', this.subscription)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>